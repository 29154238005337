import * as React from 'react'
import PropTypes from 'prop-types'
import * as CustomTypes from 'data/types'
import { graphql } from 'gatsby'

import ThemeWrapper from 'Theme'
import Layout from 'layout/BaseLayout'
import Seo from 'components/seo'

import { ModalProvider } from 'context'
import ContentBlocks, { contentBlockTypes } from 'ContentBlocks'

export const query = graphql`
  query PageQuery($pageId: String) {
    wpPage(id: { eq: $pageId }) {
      id
      slug
      title
      PageSettings {
        shortFooter
        fieldGroupName
      }
      blocks {
        ...contentBlocksQuery
      }
      seo {
        ...seoQuery
      }
    }
    wp {
      generalSettings {
        title
      }
      themeSettingsPage {
        themeSettings {
          socialLinks {
            socialLink {
              url
              title
              target
            }
            socialType
          }
        }
      }
    }
  }
`

// eslint-disable-next-line react/prop-types
export const Head = ({ data }) => {
  const {
    // eslint-disable-next-line react/prop-types
    wpPage: { title, seo },
  } = data
  return <Seo title={title} seo={seo} />
}

const PageTemplate = ({ data, location }) => {
  const {
    wpPage: { blocks, title, PageSettings },
    wp: { generalSettings, themeSettingsPage },
  } = data

  return (
    <ThemeWrapper>
      <ModalProvider>
        <Layout
          generalSettingsTitle={generalSettings.title}
          isFooterShort={PageSettings.shortFooter}
          title={title}
          socialLinksData={themeSettingsPage.themeSettings.socialLinks}
          location={location}
        >
          <ContentBlocks
            socialLinksData={themeSettingsPage.themeSettings.socialLinks}
            blocksData={blocks}
            pageLocation={location}
          />
        </Layout>
      </ModalProvider>
    </ThemeWrapper>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.shape({
    wpPage: PropTypes.shape({
      blocks: contentBlockTypes.blocksListType,
      title: PropTypes.string,
      seo: PropTypes.shape({}),
      PageSettings: PropTypes.shape({
        shortFooter: PropTypes.bool,
      }),
    }),
    wp: PropTypes.shape({
      generalSettings: PropTypes.shape({
        title: PropTypes.string,
      }),
      themeSettingsPage: PropTypes.shape({
        themeSettings: PropTypes.shape({
          socialLinks: PropTypes.arrayOf(
            PropTypes.shape({
              socialLink: PropTypes.shape({
                url: PropTypes.string,
                title: PropTypes.string,
                target: PropTypes.string,
              }),
              socialType: PropTypes.string,
            })
          ),
        }),
      }),
    }),
  }).isRequired,
  location: CustomTypes.pageLocationType.isRequired,
}
//
// Head.propTypes = {
//   data: PropTypes.shape({
//     wpPage: PropTypes.shape({
//       title: PropTypes.string,
//       seo: PropTypes.shape({}),
//     }),
//   }).isRequired,
// }

export default PageTemplate
